/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { detect } = require('detect-browser');
const ReactDOM = require('react-dom'); 

const supportedBrowsers = [
  
    { name: 'chrome', minVersion: 33 }, // 33

    { name: 'aol', minVersion: 33 },
    { name: 'edge', minVersion: 12 },
    { name: 'yandexbrowser', minVersion: 18 },
    { name: 'vivaldi', minVersion: 2 },
    { name: 'kakaotalk', minVersion: 8 },
    { name: 'samsung', minVersion: 7 },
    { name: 'silk', minVersion: 74 },
    { name: 'miui', minVersion: 2 },
    { name: 'beaker', minVersion: 0.8 },
    { name: 'edge-chromium', minVersion: 0 },
    { name: 'chromium-webview', minVersion: 33 },
    { name: 'firefox', minVersion: 29 },
    { name: 'opera', minVersion: 20 },
    { name: 'opera-mini', minVersion: 40 },
    { name: 'ie', minVersion: 11 },
    { name: 'android', minVersion: 6 },
    { name: 'safari', minVersion: 7.1 },
    { name: 'ios', minVersion: 8 },

    { name: 'ios-webview', minVersion: 0 },
    { name: 'facebook', minVersion: 0 },
    { name: 'instagram', minVersion: 0 },
    { name: 'crios', minVersion: 0 },
    { name: 'fxios', minVersion: 0 },
    { name: 'phantomjs', minVersion: 0 },
    { name: 'searchbot', minVersion: 0 },

];

const isBrowserSupported = () => {
    const browser = detect();
    const browserName = browser.name;
    const browserVersion = parseFloat(browser.version);

    if (browser.os === 'Search Bot') {
        return true;
    }

    return supportedBrowsers.reduce((is, { name, minVersion }) => (
        is || (browserName === name && browserVersion >= minVersion)
    ), false);

};

exports.replaceHydrateFunction = () => {
    return (element, container, callback) => {
        
        if (!isBrowserSupported()) {
            const UnsupportedBrowser = require('aliasTemplate/UnsupportedBrowser').default; 
            element = UnsupportedBrowser();
        }

        ReactDOM.render(element, container, callback);
    };
};