module.exports = [{
      plugin: require('/package/node_modules/gatsby-plugin-data-protect/gatsby-browser.js'),
      options: {"plugins":[],"version":"nae-v1","public_url":"https://nae.design","login_path":"login","data_protect_keys":["allBrandCenterPage","currentPage","allBrandCenterSection"],"loading_component":"/tmp/gatsby-build-40258528803741966/src/sharedComponents/templates/NaeTemplate/Loader.js"},
    },{
      plugin: require('/package/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145145097-1"},
    },{
      plugin: require('/package/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1},
    },{
      plugin: require('/tmp/gatsby-build-40258528803741966/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
