// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/tmp/gatsby-build-40258528803741966/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("/tmp/gatsby-build-40258528803741966/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("/tmp/gatsby-build-40258528803741966/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-shared-components-templates-nae-template-loader-js": () => import("/tmp/gatsby-build-40258528803741966/src/sharedComponents/templates/NaeTemplate/Loader.js" /* webpackChunkName: "component---src-shared-components-templates-nae-template-loader-js" */)
}

